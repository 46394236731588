<template>
  <div :class="{ hide: src === null }">
    <video
      ref="videoPlayer"
      class="video-js"
      :tech-order="techOrder"
      :autoplay="true"
      :controls="true"
      :preload="true"
      v-on:pause="onVideoPause"
      @changed="playerStateChanged($event)"
    ></video>
    <div class="audios">
      <li
        v-for="(item, index) in audios"
        :key="item.message"
        @click="selectAudio(index)"
        class="mb-2 mt-2"
        :class="{ active: index === audioSelected }"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 5L6 9H2V15H6L11 19V5Z"
            stroke="#B5B9C5"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19.07 4.93C20.9447 6.80528 21.9979 9.34836 21.9979 12C21.9979 14.6516 20.9447 17.1947 19.07 19.07M15.54 8.46C16.4773 9.39764 17.0039 10.6692 17.0039 11.995C17.0039 13.3208 16.4773 14.5924 15.54 15.53"
            stroke="#B5B9C5"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {{ item.title }}
      </li>
    </div>
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
import videojs from "video.js";
import "@devmobiliza/videojs-vimeo/dist/videojs-vimeo.esm";
import "videojs-youtube";
import notify from "@/services/libs/notificacao";

export default {
  name: "VideoPlayer",
  props: ["porcentagem"],
  data() {
    return {
      autoplay: true,
      player: null,
      techOrder: "html5",
      type: "audio/mpeg",
      src: "",
      audios: [],
      audioSelected: 0,
    };
  },
  computed: {},
  methods: {
    selectAudio(index) {
      this.src = this.audios[index].cdn_url;
      this.audioSelected = index;
      var myPlayer = videojs(this.$refs.videoPlayer);

      myPlayer.src([{ type: this.type, src: this.src }]);
      let cover = this.audios[index].cover;
      if (cover) {
        myPlayer.poster(this.audios[index].cover);
        return;
      }
      myPlayer.poster(
        "https://nyc3.digitaloceanspaces.com/club.greenn/media/1/background-313415.jpg"
      );
    },
    playerDispose() {
      this.videoPlayer.dispose();
    },
    playerGetTime() {
      let duration = this.videoPlayer.currentTime();
      let minutes = Math.floor(duration / 60);
      let seconds = duration - minutes * 60;
      let time = `${minutes} minutos e ${seconds}`;

      //console.log("yt - tempo atual convertido", time);
      //console.log("yt - tempo atual em segundos", duration);

      let duration2 = this.videoPlayer.duration();
      let minutes2 = Math.floor(duration2 / 60);
      let seconds2 = duration2 - minutes2 * 60;
      let time2 = `${minutes2} minutos e ${seconds2}`;

      //console.log("yt - duração total convertida", time2);
      //console.log("yt - duração total em segundos", duration2);
    },
    getPlayer() {
      this.videoPlayer = videojs(
        this.$refs.videoPlayer,
        this.options,
        function onPlayerReady() {
          //console.log("onPlayerReady", this);
        }
      );
      // var myPlayer = videojs(this.$refs.videoPlayer);
      // myPlayer.src([
      //     {type: this.type, src: this.src},
      // ]);
      // myPlayer.poster("https://nyc3.digitaloceanspaces.com/club.greenn/media/1/background-313415.jpg");
    },
    onVideoPause: function () {
      //console.log("video is paused");
    },
    play() {
      this.videoPlayer.play();
    },
    pause() {
      this.videoPlayer.pause();
      //console.log("video is paused");
    },
    onPlayerPause: function (playerCurrentState) {
      //console.log("pause", playerCurrentState);
    },
    playerStateChanged: function (playerCurrentState) {
      //console.log("player current update state", playerCurrentState);
    },
    youtubeParser(url) {
      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : false;
    },
    async calculaPorcentagem(type) {
      var current = await this.videoPlayer.currentTime();
      var total = await this.videoPlayer.duration();
      current = parseInt(current, 10);
      total = parseInt(total, 10);
      //console.log(
      //   "função q calcula",
      //   current,
      //   total,
      //   "porcentagem necessaria:",
      //   parseInt(this.porcentagem, 10)
      // );
      var calc = (current / total) * 100;
      calc = parseInt(calc, 10);
      //console.log("resultado calculo:", calc);
      if (calc >= parseInt(this.porcentagem, 10)) {
        //console.log("emite evento para liberar os btn", type);
        if (type === "concluir") {
          this.$root.$emit("liberaBtn");
        }else if(type === 'calcula'){
          this.$root.$emit('calculaBtn');
				} else {
          this.$root.$emit("liberaBtnNext");
        }
      } else {
        //console.log("caiu no else ??????");
        notify(
          "erro",
          "Você precisa atingir uma determinada parte da aula para concluir!"
        );
        //console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxx youtube");
      }
    },
  },
  mounted() {
    this.getPlayer();
    this.$root.$on("clicouConcluir", (data) => {
      if (data === "youtube") {
        //console.log("chama função q calcula");
        this.calculaPorcentagem("concluir");
      }
    });
    this.$root.$on("clicouNext", (data) => {
      if (data === "youtube") {
        this.calculaPorcentagem("next");
      }
    });
    this.$root.$on('calcula', data => {
			if(data === 'youtube'){
				this.calculaPorcentagem('calcula');
			}
		});
    this.$root.$on("dataLesson", (data) => {
      //console.log("data lesson", data);
      this.audios = data.audios;
      var myPlayer = videojs(this.$refs.videoPlayer);

      if (this.audios.length === 0) {
        myPlayer.src([{ type: this.type, src: "" }]);
        this.videoPlayer.pause();
        myPlayer.poster("");
        return;
      }
      this.getPlayer();
      this.techOrder = data.mediaType;
      if (this.techOrder === "audio") {
        this.src = data.audios[0].cdn_url;
        this.audioSelected = 0;
        myPlayer.src([{ type: this.type, src: this.src }]);
        let cover = data.audios[0].cover;
        if (cover) {
          myPlayer.poster(data.audios[0].cover);
          return;
        }
        myPlayer.poster(
          "https://nyc3.digitaloceanspaces.com/club.greenn/media/1/background-313415.jpg"
        );
      }
    });
  },
  beforeDestroy() {
    this.videoPlayer.dispose();
  },
};
</script>
<style lang="scss">
//.playerMobile div{
//  height: 35vh !important;
//}.playerMobile div .audios{
//   overflow:  inherit !important;
// }
.video-js {
  width: 100% !important;
  height: 80% !important;
  z-index: 99;
}

.vjs-error-display.vjs-modal-dialog {
  display: none;
}

.toZindex .video-js {
  z-index: 99999999999999999;
}

.hide {
  display: none !important;
}

.video-js .vjs-control-bar {
  opacity: 1 !important;
  visibility: visible;
}

.video-js .vjs-big-play-button {
  left: 45% !important;
  top: 48% !important;
}
.relativePosicao,.removeanimeminplayer{
  display: inline-block !important;
}



.audios {
  list-style-type: none;
  width: 100%;
  //height: 100px;
  overflow: auto;
  margin-top: 10px;
  padding: 0 10px;
  .active {
    border: 1px solid var(--maincolor) !important;
  }
  li {
    border: 1px solid #ededf0;
    box-sizing: border-box;
    border-radius: 5px;
    height: 55px;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: #81858e;
    font-family: Montserrat;
    padding: 17px;
    display: flex;
    justify-content: space-between;
  }
}
</style>